import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { TokenService } from 'src/app/modules/root/services/token/token.service';

import { environment } from 'src/environments/environment';

@Injectable()
export abstract class BaseHttpService {
  protected baseUrl: string;
  protected identityServerUrl: string;
  protected apiVersion: number;

  constructor(private tokenService: TokenService) {
    this.baseUrl = environment.baseUrl;
    this.identityServerUrl = environment.identityServerUrl
    this.apiVersion = environment.apiVersion;
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('Accept', 'application/json').append('Cache-Control', 'no-cache').append('Pragma', 'no-cache');
  }

  get authHeaders(): HttpHeaders {
    let token = this.tokenService.getToken();
    if (token !== undefined && token !== null && token !== '') {
      return this.headers.set('Authorization', 'Bearer ' + token);
    }

    return this.headers;
  }
}
