import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class LoadingSpinnerService {
  public isLoading = new BehaviorSubject(false);
  private _shouldBeShown: boolean;

  constructor() {
    this._shouldBeShown = false;
  }

  get shouldBeShown(): boolean {
    return this._shouldBeShown;
  }

  showLoadingSpinner(): void {
    this._shouldBeShown = true;
  }

  hideLoadingSpinner(): void {
    this._shouldBeShown = false;
  }
}
