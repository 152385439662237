<div class="home-nav d-flex align-items-start flex-column container-fluid py-3">
  <div class="d-flex justify-content-between w-100">
    <img src="../../../../../assets/images/logo-white.png">
    <div>
      <button class="btn btn-primary mr_10" (click)="registerModal.show()">Register</button>
      <button class="btn btn-primary" (click)="loginModal.show()">Login</button>
    </div>
  </div>
  <h1 class="fw_700 mx-auto">Visa First, your one-stop shop for visa and work permit applications</h1>
  <h2 class="mx-auto">Visa and immigration services - easy, fast and convenient!</h2>
</div>


<!--- Login modal -->
<div mdbModal #loginModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="passwordModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close"
          (click)="loginModal.hide(); loginForm.reset()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Login</h4>
      </div>
      <div class="modal-body text-center">
        <form (ngSubmit)="onLoginSubmit(loginModal)" [formGroup]="loginForm" novalidate>
          <div class="form-group position-relative">
            <input mdbInput type="email" id="loginEmail" placeholder="Email" formControlName="email"
              class="form-control">
            <mdb-error class="form-field-error"
              *ngIf="loginForm.get('email').errors?.required && (loginForm.get('email').dirty || loginForm.get('email').touched)">
              Email is required
            </mdb-error>
            <mdb-error class="form-field-error"
              *ngIf="loginForm.get('email').errors?.pattern && (loginForm.get('email').dirty || loginForm.get('email').touched)">
              Please enter a valid email
            </mdb-error>
          </div>
          <div class="form-group position-relative">
            <input mdbInput type="password" formControlName="password" placeholder="Password" id="loginPassword"
              class="form-control">
            <mdb-error class="form-field-error"
              *ngIf="loginForm.get('password').errors?.required && (loginForm.get('password').dirty || loginForm.get('password').touched)">
              Password is required
            </mdb-error>
          </div>
          <button class="btn btn-primary">
            Log
            in
          </button>
        </form>
      </div>
    </div>
  </div>
</div>



<!--- Register modal -->
<div mdbModal #registerModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="passwordModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="registerModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Register</h4>
      </div>
      <div class="modal-body text-center">

        <form (ngSubmit)="onRegisterSubmit(registerModal)" [formGroup]="registerForm" novalidate>
          <div class="form-group row">
            <div class="col-12 col-sm-6">
              <div class="form-group position-relative">
                <input mdbInput type="text" formControlName="firstName" class="form-control" id="firstName"
                  autocomplete="off" placeholder="First Name">
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('firstName').errors?.required && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
                  First name is required
                </mdb-error>
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('firstName').errors?.pattern && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
                  Please enter a valid name
                </mdb-error>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group position-relative">
                <input mdbInput type="text" formControlName="lastName" class="form-control" id="lastName"
                  autocomplete="off" placeholder="Last Name">
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('lastName').errors?.required && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)">
                  Last name is required
                </mdb-error>
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('lastName').errors?.pattern && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)">
                  Please enter a valid name
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-7 col-lg-6">
              <div class="row">
                <div class="col-12 col-sm-5">
                  <div class="md-form">
                    <div *ngIf="countries | async as countries">

                      <mdb-select-2 placeholder="Country code" formControlName="phoneCode">
                        <mdb-select-option *ngFor="let country of countries" [value]="country.phoneCode">
                          {{ country.isoName }} ( {{country.phoneCode}} )</mdb-select-option>
                      </mdb-select-2>
                      <mdb-error class="form-field-error"
                        *ngIf="registerForm.get('phoneCode').errors?.required && (registerForm.get('phoneCode').dirty || registerForm.get('phoneCode').touched)">
                        Required field
                      </mdb-error>


                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-7">
                  <div class="form-group position-relative">
                    <input mdbInput type="text" formControlName="phoneNumber" id="phoneNumber" class="form-control"
                      autocomplete="off" placeholder="Phone">
                    <mdb-error class="form-field-error"
                      *ngIf="registerForm.get('phoneNumber').errors?.required && (registerForm.get('phoneNumber').dirty || registerForm.get('phoneNumber').touched)">
                      Phone number is required
                    </mdb-error>
                    <mdb-error class="form-field-error"
                      *ngIf="registerForm.get('phoneNumber').errors?.pattern && (registerForm.get('phoneNumber').dirty || registerForm.get('phoneNumber').touched)">
                      Please enter a phone number
                    </mdb-error>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6">
              <div class="form-group position-relative">
                <input mdbInput type="email" formControlName="email" id="email" class="form-control" autocomplete="off"
                  placeholder="Email">
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('email').errors?.required && (registerForm.get('email').dirty || registerForm.get('email').touched)">
                  Email is required
                </mdb-error>
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('email').errors?.pattern && (registerForm.get('email').dirty || registerForm.get('email').touched)">
                  Please enter a valid email
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-sm-6">
              <div class="form-group position-relative">
                <input mdbInput type="password" formControlName="password" id="password" class="form-control"
                  autocomplete="off" placeholder="Password">
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('password').errors?.required && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                  Password is required
                </mdb-error>
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('password').errors?.minlength && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                  Must be at least 4 characters long
                </mdb-error>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group position-relative">
                <input mdbInput type="password" formControlName="passwordConfirmed" id="passwordConfirmed"
                  class="form-control" autocomplete="off" placeholder="Confirm password">
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('passwordConfirmed').errors?.required && (registerForm.get('passwordConfirmed').dirty || registerForm.get('passwordConfirmed').touched)">
                  Password confirmation is required
                </mdb-error>
                <mdb-error class="form-field-error"
                  *ngIf="registerForm.get('passwordConfirmed').errors?.compare && (registerForm.get('passwordConfirmed').dirty || registerForm.get('passwordConfirmed').touched)">
                  Passwords mismatch
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 text-left mb-2">
              <mdb-checkbox [checked]="areTermsAccepted" (change)="areTermsAccepted=!areTermsAccepted"
                formControlName="termsAndConditions">
                I agree to the
                <a href="#" target="_blank" class="pl-1">terms and conditions</a>
              </mdb-checkbox>
              <mdb-error class="form-checkbox-error"
                *ngIf="registerForm.get('termsAndConditions').errors?.required && (registerForm.get('termsAndConditions').dirty || registerForm.get('termsAndConditions').touched)">
                You have to agree with terms and conditions
              </mdb-error>
            </div>
            <div class="col-12 text-left mb-2">
              <mdb-checkbox [checked]="isUserAgreeToIRC" (change)="isUserAgreeToIRC=!isUserAgreeToIRC"
                formControlName="informationDisclosure">
                I agree to the
                <a href="" target="_blank" class="pl-1">
                  Privacy Policy
                </a>
              </mdb-checkbox>
              <mdb-error class="form-checkbox-error"
                *ngIf="registerForm.get('informationDisclosure').errors?.required && (registerForm.get('informationDisclosure').dirty || registerForm.get('informationDisclosure').touched)">
                You have to agree to continue
              </mdb-error>
            </div>
          </div>
          <div class="form-field submit">
            <button class="btn btn-primary">Create account</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>