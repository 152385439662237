export const LOCAL_STORAGE_TOKEN_KEY = 'access_token';

export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
export const LOCAL_STORAGE_ID_TOKEN_KEY = 'id_token';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const DECIMALS_PATTERN = /^\d*\.?\d*$/;
export const IDENT_NUMBER_PATTERN = /^[0-9-]*$/;
export const EMAIL_PATTERN = /[a-zA-Z0-9\u002D+_\u002e]+@[a-z]+\u002E[a-z]{2,3}/;
export const LETTERS_PATTERN = /^[a-zA-Z]+$/;
export const LETTERS_PATTERN_SPACE_DASH_APOSTROPHES = /^[a-zA-Z-\u0020\u0027 ]+$/;
export const LETTERS_AND_NUMBERS_PATTERN = /^[a-zA-Z0-9 ]*$/;
export const LETTERS_AND_SIGNS_PATTERN = /^[a-zA-Z\u0020\u002D\u002E\u0022_&(!@#$%/?,')]*$/;
export const LETTERS_NUMBERS_AND_SIGNS_PATTERN = /^[a-zA-Z0-9\u0020\u002D\u002E\u0022_&(+!@#$%/?,') ]*$/;
export const NUMBERS_PATTERN = /^[0-9]*$/;
export const YEAR_PATTERN = /^\d{4}$/;
export const PHONE_PATTERN = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const LETTERS_NUMBERS_SPACE_AND_DASH = /^[a-zA-Z0-9-\u0020]+$/;
export const LETTERS_NUMBERS_HASHTAG_AND_DASH = /^[a-zA-Z0-9-#\u0020]+$/;
export const LETTERS_NUMBERS_HASHTAG_AMPERSAND_DASH_SPACE_AND_COMMA = /^[a-zA-Z0-9-\u0020#,&]+$/;
export const NUMBERS_BRACKETS_PLUS_DASH_AND_SPACES = /^[0-9\u0020)(+-]+$/;

    