import { AbstractForm } from '../interfaces/abstract-form.interface';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { compareValidator } from '../../../directives/compare-validator/compare-validator.directive';

import { LETTERS_PATTERN, EMAIL_PATTERN, PHONE_PATTERN } from 'src/app/utilities/consts/consts';

export class RegisterForm implements AbstractForm {

  constructor(private formBuilder: FormBuilder) {

  }

  generateForm(): FormGroup {
    let firstNameControl = this.formBuilder.control('', [
      Validators.required,
      Validators.pattern(LETTERS_PATTERN)
    ]);

    let lastNameControl = this.formBuilder.control('', [
      Validators.required,
      Validators.pattern(LETTERS_PATTERN)
    ]);

    let emailFormControl = this.formBuilder.control('', [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN)
    ]);

    let phoneCodeFormControl = this.formBuilder.control(null, [
      Validators.required
    ]);

    let phoneNumberFormControl = this.formBuilder.control('', [
      Validators.required,
      Validators.pattern(PHONE_PATTERN)
    ]);

    let passwordFormControl = this.formBuilder.control('', [
      Validators.required,
      Validators.minLength(4)
    ]);

    let passwordConfirmedFormControl = this.formBuilder.control('', [
      Validators.required,
      compareValidator('password')
    ]);

    let termsAndConditionsFormControl = this.formBuilder.control(null, [
      Validators.requiredTrue
    ]);

    let informationDisclosureFormControl = this.formBuilder.control(null, [
      Validators.requiredTrue
    ]);

    return this.formBuilder.group({
      firstName: firstNameControl,
      lastName: lastNameControl,
      phoneCode: phoneCodeFormControl,
      phoneNumber: phoneNumberFormControl,
      email: emailFormControl,
      password: passwordFormControl,
      passwordConfirmed: passwordConfirmedFormControl,
      termsAndConditions: termsAndConditionsFormControl,
      informationDisclosure: informationDisclosureFormControl
    });
  }
}
