import { AbstractForm } from '../interfaces/abstract-form.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { EMAIL_PATTERN } from 'src/app/utilities/consts/consts';

export class LoginForm implements AbstractForm {

  constructor(private formBuilder: FormBuilder) {
  }

  generateForm(): FormGroup {
    let emailFormControl = this.formBuilder.control(null, [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN)
    ]);

    let passwordFormControl = this.formBuilder.control(null, [
      Validators.required
    ]);

    return this.formBuilder.group({
      email: emailFormControl,
      password: passwordFormControl
    }, { updateOn: 'blur' });
  }
}
