import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {
  public isAuthenticated = false;

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  ngOnInit(): void {
    if(!this.isAuthenticated) {
      window.location.href = environment.signOutUrl;
    }else {
      this.router.navigate(['user']);
    }
  }

}
