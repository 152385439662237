import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { TokenService } from 'src/app/modules/root/services/token/token.service';

import { Login } from './models/login.model';
import { Register } from './models/register.model';
import { TokenModel } from 'src/app/modules/root/services/token/models/token.model';
import { BaseHttpService } from '../base-http/base-http.service';

@Injectable()
export class AccountService extends BaseHttpService {

  constructor(tokenService: TokenService, private httpClient: HttpClient) {
    super(tokenService)
  }

  get tokenHeaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Accept', 'application/json');
  }

  register(model: Register): Observable<any> {
    return this.httpClient.post<any>(`${this.identityServerUrl}api/Account`, model, { headers: this.headers });
  }

  login(model: Login): Observable<TokenModel> {
    return this.httpClient.post<TokenModel>(`${this.identityServerUrl}api/account/login`, model, { headers: this.headers })
  }

  token(model): Observable<TokenModel> {
    return this.httpClient.post<TokenModel>(`${this.identityServerUrl}connect/token`, model, { headers: this.tokenHeaders })
  }

  /* Methods required authorization */

  setPersonalInfo(model: any): Observable<any> {
    return this.httpClient.put<any>(`${this.baseUrl}api/v${this.apiVersion}/PersonalInformation`, model, { headers: this.authHeaders });
  }

  getPersonalInfo(): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}api/v${this.apiVersion}/PersonalInformation`, { headers: this.authHeaders });
  }

  getUserClientType(marketingCode: string): Observable<any> {
    if(marketingCode) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('marketingCode', marketingCode);
      
      return this.httpClient.get<any>(`${this.baseUrl}api/v${this.apiVersion}/UserAccounts/GetUserClientType`, { headers: this.authHeaders, params: httpParams });
    }

    return this.httpClient.get<any>(`${this.baseUrl}api/v${this.apiVersion}/UserAccounts/GetUserClientType`, { headers: this.authHeaders });
  }

  logout(): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}api/v${this.apiVersion}/Account/logout`, null, { headers: this.authHeaders });
  }
  
  updateDefaultCurrency(currency: string) {
    return this.httpClient.post(`${this.baseUrl}api/v${this.apiVersion}/UserAccounts/UpdateCurrentUserDefaultCurrency`, '', { headers: this.authHeaders, params: { currency } })
  }
}
