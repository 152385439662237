import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseHttpService } from '../base-http/base-http.service';
import { TokenService } from 'src/app/modules/root/services/token/token.service';

import { EligibilityChecker } from './models/eligibility-checker.model';
import { VisaResult } from './models/visa-result.model';

@Injectable()
export class EligibilityCheckerService extends BaseHttpService {

  constructor(tokenService: TokenService, private httpClient: HttpClient) { 
    super(tokenService);
  }

  checkEligibility(model: EligibilityChecker): Observable<any> {
  
    return this.httpClient.post<any>(`${this.baseUrl}api/v${this.apiVersion}/EligibilityChecker`, model, { headers: this.authHeaders, observe: 'response' });
  }
}
