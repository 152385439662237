import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CountriesService } from '../services/countries/countries.service';

@Injectable({
  providedIn: 'root'
})
export class VisaStoreService {
  orderItemsCounter$ = new BehaviorSubject<number>(0);
  
  private countries$: Observable<any[]>;

  constructor(private countriesService: CountriesService) { }

  getCountries(): Observable<any[]> {
    
    if(this.countries$ === null || this.countries$ === undefined) {
      this.countries$ = this.countriesService.getCountries();
    }

    return this.countries$;
  }
}
