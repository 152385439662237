import { Injectable } from '@angular/core';

import { Adapter } from '../../adapters/adapter.interface';

export class Country {
  constructor(
    public isoName: string,
    public iso2: string,
    public iso3: string,
    public phoneCode: string,
    public isEUCountry: boolean,
    public isVIESCountry: boolean,
    public visaProducts: any //Create VisaProduct model
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class CountryAdapter implements Adapter<Country> {

  adapt(item: any): Country {
    return new Country(
      item.isoName,
      item.iso2,
      item.iso3,
      item.phoneCode,
      item.isEUCountry,
      item.isVIESCountry,
      item.visaProducts
    );
  }
}
