import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '../base-http/base-http.service';
import { TokenService } from 'src/app/modules/root/services/token/token.service';

import { Country, CountryAdapter } from './models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends BaseHttpService {

  constructor(tokenService: TokenService, private httpClient: HttpClient, private adapter: CountryAdapter) {
    super(tokenService)
  }

  getCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`${this.baseUrl}api/v${this.apiVersion}/CommonValues/Countries`)
      .pipe(
        map((data: any[]) => data.map(item => this.adapter.adapt(item))));
  }
}
