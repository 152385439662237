import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private _isMobile: boolean;
  private _isNewUser: boolean;

  constructor() { }

  //Setter and Getter of isMobile flag (used in the entire app)
  get isMobile(): boolean {
    return this._isMobile;
  }

  setIsMobile(state: boolean) {
    this._isMobile = state;
  }

  //Setter and Getter of isNewUser flag (used in Overview)
  get isNewUser(): boolean {
    return this._isNewUser;
  }

  setIsNewUser(state: boolean) {
    this._isNewUser = state;
  }
}
