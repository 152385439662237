import { Component, OnInit } from '@angular/core';

import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'access-denied-message',
  templateUrl: './access-denied-message.component.html',
  styleUrls: ['./access-denied-message.component.scss']
})
export class AccessDeniedMessageComponent implements OnInit {
  title?: string;
  warningMessage?: string;
  message: string;
  close: Subject<any>;

  constructor(
    public modalRef: MDBModalRef,
    private authService: AuthService) {
    this.close = new Subject<any>();
  }

  ngOnInit(): void {
  }

  onCloseButtonClicked(): void {
    this.modalRef.hide();
    this.close.next();
  }

  redirectToCorporate(): void {
    this.authService.logout()
  }

}
