import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ContentService } from 'src/app/modules/user/shared/services/content/content.service';
import { LoadingSpinnerService } from 'src/app/modules/shared/services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, AfterViewInit {

  constructor(public loadingSpinnerService: LoadingSpinnerService, private contentService: ContentService ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (window.innerWidth <= 767) {
      this.contentService.setIsMobile(true)
    } else {
      this.contentService.setIsMobile(false)
    }
  }

  onResize(event) {
    const w = event.target.innerWidth;
    if (w <= 767) {
      this.contentService.setIsMobile(true)
    } else {
      this.contentService.setIsMobile(false)
    }
  }
}
