import { Injectable } from '@angular/core';

import { SubdomainURLModel } from './models/subdomain-url.model';
import { SubdomainTypes } from './helpers/subdomain-types.enum';

@Injectable({
  providedIn: 'root'
})
export class ThemeHandlerService {
  private linkDetails: SubdomainURLModel;
  isSubdomain: boolean = true;
  isPartnerLink: boolean = false;

  subdomainTypes: typeof SubdomainTypes;

  constructor() {
    this.subdomainTypes = SubdomainTypes;
  }

  setTheme(hostDetails: SubdomainURLModel): void {
    this.startTheme(hostDetails.host);
    this.linkDetails = hostDetails;

    this.isPartnerLink = !!(hostDetails.applicationType && hostDetails.marketingCode && hostDetails.productIdentifier);
  }

  getLinkDetails(): SubdomainURLModel {
    return this.linkDetails;
  }

  private startTheme(host: string): void {
    switch (host) {
      // This switch case is used for testing on staging
      // case this.subdomainTypes.stagingSubdomain: {
      //   document.body.classList.add(this.subdomainTypes.wendywu);
      //   break;
      // }
      // This switch case is used for testing on localhost: 4000
      // case this.subdomainTypes.localSubdomain: {
      //   document.body.classList.add(this.subdomainTypes.wendywu);
      //   break;
      // }
      case this.subdomainTypes.scandimate: {
        document.body.classList.add(this.subdomainTypes.scandimate);
        break;
      }
      case this.subdomainTypes.wendywu: {
        document.body.classList.add(this.subdomainTypes.wendywu);
        break;
      }
      case this.subdomainTypes.winbergtravel: {
        document.body.classList.add(this.subdomainTypes.winbergtravel);
        break;
      }
      case this.subdomainTypes.jyskejsebureau: {
        document.body.classList.add(this.subdomainTypes.jyskejsebureau);
        break;
      }
      case this.subdomainTypes.kilroyBe: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kilroyDk: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kilroyFi: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kilroyIs: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kilroySe: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kilroyUk: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kirloyNo: {
        document.body.classList.add('kilroy');
        break;
      }
      case this.subdomainTypes.kilroyworldNl: {
        document.body.classList.add('kilroy');
        break;
      }
      default:{
        document.body.classList.add(this.subdomainTypes.visafirst);
        this.isSubdomain = false;
        break;
      }
    }
  }
}
