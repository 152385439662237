import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription, Observable } from 'rxjs';

import { ToastService, MDBModalRef } from 'ng-uikit-pro-standard';

import { AccountService } from 'src/app/modules/shared/services/account/account.service';
import { TokenService } from '../../services/token/token.service';
import { VisaStoreService } from 'src/app/modules/shared/store/visa-store.service';

import { AbstractForm } from 'src/app/modules/shared/services/form-factory/interfaces/abstract-form.interface';
import { RegisterForm } from 'src/app/modules/shared/services/form-factory/classes/register-form';
import { LoginForm } from 'src/app/modules/shared/services/form-factory/classes/login-form';
import { TokenModel } from '../../services/token/models/token.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Login } from 'src/app/modules/shared/services/account/models/login.model';
import { Register } from 'src/app/modules/shared/services/account/models/register.model';

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  formFactory: AbstractForm;
  loginForm: FormGroup;
  registerForm: FormGroup;

  areTermsAccepted: boolean;
  isUserAgreeToIRC: boolean;

  countries: Observable<any[]>;

  toastOptions = { positionClass: 'md-toast-bottom-right' };

  private subscription: Subscription;

  constructor(private formBuilder: FormBuilder, private visaStoreService: VisaStoreService, private accountService: AccountService, private tokenService: TokenService, private router: Router, private toasterService: ToastService) {
    this.areTermsAccepted = false;
    this.isUserAgreeToIRC = false;

    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.loginForm = this.generateLoginForm();
    this.registerForm = this.generateRegisterForm();

    this.countries = this.visaStoreService.getCountries();
  }

  onLoginSubmit(loginModal: MDBModalRef): void {
    if (this.loginForm.valid) {
      let loginModelDto: Login = this.loginForm.value;
      
      this.subscription.add(this.accountService.login(loginModelDto).subscribe(() => {
        let IdentitytokenModel =  
          encodeURIComponent('client_id') +'='+ encodeURIComponent('sprintax')+'&'+
          encodeURIComponent('client_secret') +'='+ encodeURIComponent('secret')+'&'+
          encodeURIComponent('grant_type') +'='+ encodeURIComponent('password')+'&'+
          encodeURIComponent('scope') +'='+ encodeURIComponent('Sprintax_2.0')+'&'+
          encodeURIComponent('username') +'='+ encodeURIComponent(loginModelDto.email)+'&'+
          encodeURIComponent('password') +'='+ encodeURIComponent(loginModelDto.password);

        this.accountService.token(IdentitytokenModel).subscribe((response) => {
          this.tokenService.setToken(response.access_token);
          this.router.navigate(['user']);

          this.toasterService.success('You are successfully logged in', null, this.toastOptions);
          this.loginForm.reset();

          loginModal.hide();
        });
      }, (errorResponse: HttpErrorResponse) => {
        console.log(errorResponse);
        
      }));
    }
    else {
      this.loginForm.markAllAsTouched();
    }
  }

  onRegisterSubmit(registerModal: MDBModalRef): void {
    if (this.registerForm.valid) {
      let formResult = this.registerForm.value;

      let iSModel: Register = {
        "firstName": formResult.firstName,
        "lastName": formResult.lastName,
        "email": formResult.email,
        "phoneCode": formResult.phoneCode,
        "phoneNumber": formResult.phoneNumber,
        "password": formResult.password,
        "passwordConfirmed": formResult.passwordConfirmed,
        "confirmEmailUrl": "https://www.visafirst.com/",
        "system": 3, // Sp2_0 = 1, TDSCa = 2, SpCa = 3, TaxbackAPI = 4
      };
      
      this.subscription.add(this.accountService.register(iSModel).subscribe(() => {
        this.toasterService.success('Your registration is successful', null, this.toastOptions);
        this.registerForm.reset();

        registerModal.hide();
      }, (errorResponse: HttpErrorResponse) => {
        this.toasterService.error(errorResponse.error, null, this.toastOptions);
      }));
    }
    else {
      this.registerForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private generateRegisterForm(): FormGroup {
    this.formFactory = new RegisterForm(this.formBuilder);

    return this.formFactory.generateForm();
  }

  private generateLoginForm(): FormGroup {
    this.formFactory = new LoginForm(this.formBuilder);

    return this.formFactory.generateForm();
  }
}
