export enum SubdomainTypes {
  stagingSubdomain = '192',
  localSubdomain = 'subdomain',
  visafirst = 'visafirst',
  scandimate = 'scandimate',
  jyskejsebureau = 'jysk-rejsebureau',
  kilroyFi = 'kilroy-fi',
  kilroyDk = 'kilroy-dk',
  kilroyBe = 'kilroy-be',
  kilroyIs = 'kilroy-is',
  kilroyworldNl = 'kilroyworld-nl',
  kirloyNo = 'kilroy-no',
  kilroySe = 'kilroy-se',
  kilroyUk = 'kilroy-uk',
  winbergtravel = 'winbergtravel',
  wendywu = 'wendywu',
}