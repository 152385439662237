export enum FetchDescriptorTypes {
    CountryOfResidence = 'FetchDescriptor/GetById/CountryOfResidence',
    KenyaAirEntryPoints = 'FetchDescriptor/GetById/KenyaAirEntryPoints',
    KenyaShipEntryPoints = 'FetchDescriptor/GetById/KenyaShipEntryPoints',
    KenyaRoadEntryPoints = 'FetchDescriptor/GetById/KenyaRoadEntryPoints',
    KenyaDurations = 'FetchDescriptor/GetById/KenyaDurations',
    ArrivingTransportTypes = 'FetchDescriptor/GetById/ArrivingTransportTypes',
    KenyaTravelReasons = 'FetchDescriptor/GetById/KenyaTravelReasons',
    KenyaHostTypes = 'FetchDescriptor/GetById/KenyaHostTypes',
    KenyaHostsNationalities = 'FetchDescriptor/GetById/KenyaHostsNationalities',
    AustraliaPreviousVisaAfectedByCovid19Types = 'FetchDescriptor/GetById/AustraliaPreviousVisaAfectedByCovid19Types',
    HighestQualifications = 'FetchDescriptor/GetById/HighestQualifications',
    Nationality = 'FetchDescriptor/GetById/Nationality',
    AcquireNationalityMethods = 'FetchDescriptor/GetById/AcquireNationalityMethods',
    UsaPassportIssuingCountries = 'FetchDescriptor/GetById/UsaPassportIssuingCountries',
    IntendedOccupations = 'FetchDescriptor/GetById/IntendedOccupations',
    AzerbaijanOccupations = 'FetchDescriptor/GetById/AzerbaijanOccupations',
    ConsiderationPeriods = 'FetchDescriptor/GetById/ConsiderationPeriods',
    AzerbaijanVisitPurposes = 'FetchDescriptor/GetById/AzerbaijanVisitPurposes',
    TravelDocuments = 'FetchDescriptor/GetById/TravelDocuments',
    CambodiaEntryPorts = 'FetchDescriptor/GetById/CambodiaEntryPorts',
    VietnamEntryExitPoints = 'FetchDescriptor/GetById/VietnamEntryExitPoints',
    UsaTravelDocumentTypes = 'FetchDescriptor/GetById/UsaTravelDocumentTypes',
    EgyptApplicationTypes = 'FetchDescriptor/GetById/EgyptApplicationTypes',
    KenyaTravelingDocumentTypes = 'FetchDescriptor/GetById/KenyaTravelingDocumentTypes',
    Continents = 'FetchDescriptor/GetById/Continents',
    MaritalStatuses = 'FetchDescriptor/GetById/MaritalStatuses',
    EnterHospitalReasons = 'FetchDescriptor/GetById/EnterHospitalReasons',
    LaosTravelDocumentTypes = 'FetchDescriptor/GetById/LaosTravelDocumentTypes',
    UsaStates = 'FetchDescriptor/GetById/UsaStates',
    PhoneCodes = 'FetchDescriptor/GetById/PhoneCodes',
    OffenceTypes = 'FetchDescriptor/GetById/OffenceTypes',
    IdTypes = 'FetchDescriptor/GetById/IdTypes',
    Occupations = 'FetchDescriptor/GetById/Occupations',
    UsaOtherCountryTravelReasons = 'FetchDescriptor/GetById/UsaOtherCountryTravelReasons',
    VietnamVisitPurposes = 'FetchDescriptor/GetById/VietnamVisitPurposes',
    SocialPlatformProviders = 'FetchDescriptor/GetById/SocialPlatformProviders',
    AgedOrDisabilityCareRoles = 'FetchDescriptor/GetById/AgedOrDisabilityCareRoles',
    IntendedRoles = 'FetchDescriptor/GetById/IntendedRoles',
    MedicalConditions = 'FetchDescriptor/GetById/MedicalConditions',
    AustraliaEmploymentTypes = 'FetchDescriptor/GetById/AustraliaEmploymentTypes',
    AustraliaIndustryTypes = 'FetchDescriptor/GetById/AustraliaIndustryTypes',
    AustralianStates = 'FetchDescriptor/GetById/AustralianStates',
    AustraliaEligibleCountries = 'FetchDescriptor/GetById/AustraliaEligibleCountries',
    OtherIdentityDocumentTypes = 'FetchDescriptor/GetById/OtherIdentityDocumentTypes',
    LegalStatuses = 'FetchDescriptor/GetById/LegalStatuses',
    IndiaPorts = 'FetchDescriptor/GetById/IndiaPorts',
    IndiaBusinessVisitPurposes = 'FetchDescriptor/GetById/IndiaBusinessVisitPurposes',
    IndiaReligions = 'FetchDescriptor/GetById/IndiaReligions',
    IndiaEducationQualifications = 'FetchDescriptor/GetById/IndiaEducationQualifications',
    IndiaAcquireNationalityMethods = 'FetchDescriptor/GetById/IndiaAcquireNationalityMethods',
    IndiaMaritalStatuses = 'FetchDescriptor/GetById/IndiaMaritalStatuses',
    IndiaOccupationTypes = 'FetchDescriptor/GetById/IndiaOccupationTypes',
    IndiaVisaTypes = 'FetchDescriptor/GetById/IndiaVisaTypes',
    SaarcCountries = 'FetchDescriptor/GetById/SaarcCountries',
    IndiaStates = 'FetchDescriptor/GetById/IndiaStates',
    IndiaTouristVisitPurposes = 'FetchDescriptor/GetById/IndiaTouristVisitPurposes',
    SaudiArabiaPurposeOfVisitTypes = 'FetchDescriptor/GetById/SaudiArabiaPurposeOfVisitTypes',
    SaudiArabiaResidenceAddressTypes = 'FetchDescriptor/GetById/SaudiArabiaResidenceAddressTypes',
    LaosEntryPorts = 'FetchDescriptor/GetById/LaosEntryPorts',
    GccCountries = 'FetchDescriptor/GetById/GccCountries',
    OmanTravelDocumentTypes = 'FetchDescriptor/GetById/OmanTravelDocumentTypes',
    TanzaniaResidenceStatuses = 'FetchDescriptor/GetById/TanzaniaResidenceStatuses',
    TanzaniaEmploymentStatuses = 'FetchDescriptor/GetById/TanzaniaEmploymentStatuses',
    TanzaniaEmbassies = 'FetchDescriptor/GetById/TanzaniaEmbassies',
    TanzaniaVisaRequestedTypes = 'FetchDescriptor/GetById/TanzaniaVisaRequestedTypes',
    TanzaniaBusinessVisitPurposes = 'FetchDescriptor/GetById/TanzaniaBusinessVisitPurposes',
    TanzaniaOrdinaryVisitPurposes = 'FetchDescriptor/GetById/TanzaniaOrdinaryVisitPurposes',
    TanzaniaDestinations = 'FetchDescriptor/GetById/TanzaniaDestinations',
    ZanzibarPortTypes = 'FetchDescriptor/GetById/ZanzibarPortTypes',
    TanzaniaPortTypes = 'FetchDescriptor/GetById/TanzaniaPortTypes',
    TanzanianHostTypes = 'FetchDescriptor/GetById/TanzanianHostTypes',
    TanzaniaPlaceToStayTypes = 'FetchDescriptor/GetById/TanzaniaPlaceToStayTypes',
    AustraliaStayPurposes = 'FetchDescriptor/GetById/AustraliaStayPurposes',
    AustraliaVisitReasons = 'FetchDescriptor/GetById/AustraliaVisitReasons',
    SpecialEntryCategories = 'FetchDescriptor/GetById/SpecialEntryCategories',
    RelationshipToTheApplicantTypes = 'FetchDescriptor/GetById/RelationshipToTheApplicantTypes',
    Sex = 'FetchDescriptor/GetById/Sex',
    VisaDurations = 'FetchDescriptor/GetById/VisaDurations',
    EmploymentStatuses = 'FetchDescriptor/GetById/EmploymentStatuses',
    AustraliaStayFundingTypes = 'FetchDescriptor/GetById/AustraliaStayFundingTypes',
    AustraliaSupportTypes = 'FetchDescriptor/GetById/AustraliaSupportTypes',
    ParentDetailsTypes = 'FetchDescriptor/GetById/ParentDetailsTypes',
    VisaApplicantTypes = 'FetchDescriptor/GetById/VisaApplicantTypes',
    CanadaMedicalConditions = 'FetchDescriptor/GetById/CanadaMedicalConditions',
    VietnamCitiesProvinces = 'FetchDescriptor/GetById/VietnamCitiesProvinces',
    CanadaOccupations = 'FetchDescriptor/GetById/CanadaOccupations',
    CanadaArtCultureAndRecreationOccupations = 'FetchDescriptor/GetById/CanadaArtCultureAndRecreationOccupations',
    CanadaBusinessAndFinanceOccupations = 'FetchDescriptor/GetById/CanadaBusinessAndFinanceOccupations',
    CanadaEducationLawAndSocialOccupations = 'FetchDescriptor/GetById/CanadaEducationLawAndSocialOccupations',
    CanadaHealthOccupations = 'FetchDescriptor/GetById/CanadaHealthOccupations',
    CanadaManufacturingAndUtilitiesOccupations = 'FetchDescriptor/GetById/CanadaManufacturingAndUtilitiesOccupations',
    CanadaMilitaryArmedForcesOccupations = 'FetchDescriptor/GetById/CanadaMilitaryArmedForcesOccupations',
    CanadaNaturalAndAppliedSciencesOccupations = 'FetchDescriptor/GetById/CanadaNaturalAndAppliedSciencesOccupations',
    CanadaNaturalResourcesAgricultureOccupations = 'FetchDescriptor/GetById/CanadaNaturalResourcesAgricultureOccupations',
    CanadaSalesAndServiceOccupations = 'FetchDescriptor/GetById/CanadaSalesAndServiceOccupations',
    CanadaTradesAndTransportOccupations = 'FetchDescriptor/GetById/CanadaTradesAndTransportOccupations',
    CanadaManagmentOccupations = 'FetchDescriptor/GetById/CanadaManagmentOccupations',
    YearsFrom1900 = 'FetchDescriptor/GetById/YearsFrom1900',
    CanadaTimeZoneTypes = 'FetchDescriptor/GetById/CanadaTimeZoneTypes',
    Hours = 'FetchDescriptor/GetById/Hours',
    Minutes = 'FetchDescriptor/GetById/Minutes',
    AustraliaVisitPurposes = 'FetchDescriptor/GetById/AustraliaVisitPurposes',
    LastFourYears = 'FetchDescriptor/GetById/LastFourYears',
    VisaServiceTypes = 'FetchDescriptor/GetById/VisaServiceTypes',
    ChinaReturnTypes = 'FetchDescriptor/GetById/ChinaReturnTypes',
    ChinaBusinessVisaTypes = 'FetchDescriptor/GetById/ChinaBusinessVisaTypes',
    ChinaTouristVisaTypes = 'FetchDescriptor/GetById/ChinaTouristVisaTypes',
    ChinaTripFunderTypes = 'FetchDescriptor/GetById/ChinaTripFunderTypes',
    ChinaResidentStatusTypes = 'FetchDescriptor/GetById/ChinaResidentStatusTypes',
    ChinaExchangeTypes = 'FetchDescriptor/GetById/ChinaExchangeTypes',
    ChinaCommercialTradeActivityTypes = 'FetchDescriptor/GetById/ChinaCommercialTradeActivityTypes',
    ChinaApplicationReasonTypes = 'FetchDescriptor/GetById/ChinaApplicationReasonTypes',
    ChinaOccupationTypes = 'FetchDescriptor/GetById/ChinaOccupationTypes',
    SaudiArabiaReturnTypes = 'FetchDescriptor/GetById/SaudiArabiaReturnTypes',
    CubaReturnTypes = 'FetchDescriptor/GetById/CubaReturnTypes',
    SriLankaVisitPurposes = 'FetchDescriptor/GetById/SriLankaVisitPurposes',
    TanzaniaBusinessResidenceStatuses = 'FetchDescriptor/GetById/TanzaniaBusinessResidenceStatuses',
    ThailandVisitPurposes = 'FetchDescriptor/GetById/ThailandVisitPurposes',
    ThailandOccupations = 'FetchDescriptor/GetById/ThailandOccupations',
    ThailandAnnualIncomes = 'FetchDescriptor/GetById/ThailandAnnualIncomes',
    ThailandArrivalPorts = 'FetchDescriptor/GetById/ThailandArrivalPorts',
    ThailandFlightTypes = 'FetchDescriptor/GetById/ThailandFlightTypes',
    ThailandAccommodationTypes = 'FetchDescriptor/GetById/ThailandAccommodationTypes',
    ThailandCities = 'FetchDescriptor/GetById/ThailandCities',
    ApplicantFundingPersonTypes = 'FetchDescriptor/GetById/ApplicantFundingPersonTypes',
    VietnamPaymentMethods = 'FetchDescriptor/GetById/VietnamPaymentMethods',
    PassportTypes = 'FetchDescriptor/GetById/PassportTypes',
    OtherNamesDetailsTypes = 'FetchDescriptor/GetById/OtherNamesDetailsTypes',
    RelationshipStatuses = 'FetchDescriptor/GetById/RelationshipStatuses',
    Currencies = 'FetchDescriptor/GetById/Currencies',
    FirstLanguageTypes = 'FetchDescriptor/GetById/FirstLanguageTypes',
    CanadaWorkingHolidayCommunicationLanguages = 'FetchDescriptor/GetById/CanadaWorkingHolidayCommunicationLanguages',
    CanadaWorkingHolidayImmigrationStatuses = 'FetchDescriptor/GetById/CanadaWorkingHolidayImmigrationStatuses',
    CanadaWorkingHolidayLanguageOfCorrespondenceTypes = 'FetchDescriptor/GetById/CanadaWorkingHolidayLanguageOfCorrespondenceTypes',
    CanadaWorkingHolidayTelephoneTypes = 'FetchDescriptor/GetById/CanadaWorkingHolidayTelephoneTypes',
    CanadaWorkingHolidayCountries = 'FetchDescriptor/GetById/CanadaWorkingHolidayCountries',
    Months = 'FetchDescriptor/GetById/Months',
    CanadaWorkingHolidayJobTitles = 'FetchDescriptor/GetById/CanadaWorkingHolidayJobTitles',
    CanadaWorkingHolidayLevelOfStudyTypes = 'FetchDescriptor/GetById/CanadaWorkingHolidayLevelOfStudyTypes',
    CanadaWorkingHolidayFieldsOfStudy = 'FetchDescriptor/GetById/CanadaWorkingHolidayFieldsOfStudy',
    ChinaMaritalStatuses = 'FetchDescriptor/GetById/ChinaMaritalStatuses',
    SriLankaPersonTitles = 'FetchDescriptor/GetById/SriLankaPersonTitles',
    sriLankaEntryPorts = 'FetchDescriptor/GetById/sriLankaEntryPorts',
    sriLankaBusinessVisitPurposes = 'FetchDescriptor/GetById/sriLankaBusinessVisitPurposes',

    // Other frequently used endpoints with static data
    GetAllApplicationForms = '/FormGenerator/GetAllApplicationForms',
    GetApplicationsInProgressSortKeys = '/Application/GetApplicationsInProgressSortKeys',
    GetApplicationsInProgressFilters = '/Application/GetApplicationsInProgressFilters',
    GetApplicationsHistoryFilters = '/Application/GetApplicationsHistoryFilters',
    GetApplicationsHistorySortKeys = '/Application/GetApplicationsHistorySortKeys'
}
  