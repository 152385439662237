import { NgModule } from '@angular/core';

import { DirectivesModule } from './directives/directives.module';
import { MDBootstrapModule } from './mdbootstrap/mdbootstrap.module';

import { AccountService } from './services/account/account.service';
import { EligibilityCheckerService } from './services/eligibility-checher/eligibility-checker.service';

@NgModule({
  imports: [
    DirectivesModule,
    MDBootstrapModule
  ],
  providers: [
    AccountService,
    EligibilityCheckerService
  ],
  exports: [
    DirectivesModule,
    MDBootstrapModule
  ]
})
export class SharedModule { }
