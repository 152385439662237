import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentStoreService {
  _language: string;
  customerId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  defaultCurrency$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  
  constructor() {
  }

  setDefaultCurrency(currency: string): void {
    this.defaultCurrency$.next(currency);
  }

  setCustomerId(customerId: string): void {
    this.customerId$.next(customerId);
  };

  getLanguage(): string {
    
    return this._language;
  }
}
