import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CanActivate, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { NotificationType } from 'src/app/utilities/enums/notification-type.enum';

import { OAuthService} from 'angular-oauth2-oidc';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { AuthService } from '../../services/auth/auth.service';
import { AccountService } from 'src/app/modules/shared/services/account/account.service';
import { LoadingSpinnerService } from 'src/app/modules/shared/services/loading-spinner/loading-spinner.service';

import { EligibilityChecker } from 'src/app/modules/shared/services/eligibility-checher/models/eligibility-checker.model';
import { EnvironmentStoreService } from 'src/app/modules/shared/store/environment-store.service';
import { ThemeHandlerService } from 'src/app/modules/user/shared/services/theme-handler/theme-handler.service';

import { AccessDeniedMessageComponent } from '../../components/access-denied-message/access-denied-message.component';
import { SubdomainURLModel } from 'src/app/modules/user/shared/services/theme-handler/models/subdomain-url.model';
import { EnumHelpers } from 'src/app/modules/shared/services/helpers-services/enum-helper/enum-helpers.service';
import { SubdomainTypes } from 'src/app/modules/user/shared/services/theme-handler/helpers/subdomain-types.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  notificationType: typeof NotificationType;
  subdomainTypes: typeof SubdomainTypes;
  modalRef: MDBModalRef;

  modalOptions = {
    backdrop: false,
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: 'modal-dialog',
    containerClass: '',
    animated: true,
    data: {
      title: 'Warning', 
      warningMessage: 'You do not have access to a Personal account.',
      message: 'If you want to login to your Corporate account, please select "Corporate" from the login menu.' 
    }
  }

  private subscription: Subscription;

  private marketingCode: string = null;

  constructor(
    private authService: AuthService,
    private oauthService: OAuthService, 
    private modalService: MDBModalService,
    private accountService: AccountService,
    private environmentStoreService: EnvironmentStoreService,
    private loadingSpinnerService: LoadingSpinnerService,
    private themeHandlerService: ThemeHandlerService,
    private enumHelperService: EnumHelpers) {
      this.notificationType = NotificationType;
      this.subdomainTypes = SubdomainTypes;
      this.subscription = new Subscription();
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.authService.isAuthenticated()) {
        return new Observable<boolean>(obs => {
          this.loadingSpinnerService.showLoadingSpinner();

          if (localStorage.getItem('ref')) {
            const ref = localStorage.getItem('ref');

            this.setReferralDetails(ref);

            localStorage.removeItem('ref');
          } else {
            let ref = route.queryParams.ref;
            
            if (!ref) {
              const url = document.location.href;

              if (url.includes('?') && url.includes('?ref=')) {
                ref = url.split('?ref=')[1];
              }
            } 
            this.setReferralDetails(ref);
          }

          if (localStorage.getItem("productIdentifier") && localStorage.getItem("marketingCode") && localStorage.getItem("applicationType")) {
            const routeDetails = {
              productIdentifier: localStorage.getItem("productIdentifier"),
              marketingCode: localStorage.getItem("marketingCode"),
              applicationType: localStorage.getItem("applicationType"),
            }
            
            this.getHostTheme(routeDetails);

            localStorage.removeItem("productIdentifier");
            localStorage.removeItem("marketingCode");
            localStorage.removeItem("applicationType");
          } else {
            const routeDetails = {
              productIdentifier: route.queryParams.productIdentifier,
              marketingCode: route.queryParams.marketingCode,
              applicationType: route.queryParams.applicationType,
            }
            
            this.getHostTheme(routeDetails);
          }

          // Check if Marketing partner query params
          if(Object.keys(route.queryParams).length !== 0 && route.queryParams.partnerId) {
            this.marketingCode = String(route.queryParams.partnerId)
          } else if (localStorage.getItem("partner_id")) {
            this.marketingCode = localStorage.getItem("partner_id")
          } 

          this.subscription.add(this.accountService.getUserClientType(this.marketingCode).subscribe((userAccount) => {
            localStorage.removeItem("partner_id")
            
            this.environmentStoreService.setCustomerId(userAccount.clientId);
            this.environmentStoreService.setDefaultCurrency(userAccount.defaultCurrency);

            this.loadingSpinnerService.hideLoadingSpinner()

            if(userAccount.clientType === 'Individual') {
              // Check if Email Notifications query params
              this.setStorageEmailNotifyParams(route)
              
              // Check if WP - Eligibility Checker query params
              this.storeEligibilityCheckerQueryParams(route)

              // Check if Email Notifications unsubscribe
              this.setStorageEmailNotifyUnsubscribe(route)
      
              /**
                * Will setup up silent refreshing for when the token is about to expire. 
                * When the user is logged out via this.logOut method, the silent refreshing will pause and not refresh the tokens until the user is logged back
                * in via receiving a new token.
                */
              this.oauthService.setupAutomaticSilentRefresh();
     
              obs.next(true);
            }else {
              this.modalRef = this.modalService.show(AccessDeniedMessageComponent, this.modalOptions);
 
              obs.next(false);
            }
          }, (errorResponse: HttpErrorResponse) => {
            this.loadingSpinnerService.hideLoadingSpinner()
            console.log(errorResponse);
           
          }));
        });
      } else {
        // Check if WP - Eligibility Checker query params
        this.storeEligibilityCheckerQueryParams(route);

        // Check if Email Notifications query params
        this.setStorageEmailNotifyParams(route);

        // Check if Email Notifications unsubscribe
        this.setStorageEmailNotifyUnsubscribe(route);

        // Check if Marketing partner query params
        this.setStorageMarketingPartnerLink(route);

        // Check if partner link query params
        this.setStorageProductPartnerLink(route);

        // Check if referral link query params
        this.setStorageReferralDetails(route);

        this.authService.login();

        return false;
      }
  }

  private setStorageReferralDetails(route: ActivatedRouteSnapshot): void {
    if(Object.keys(route.queryParams).length !== 0 && route.queryParams.ref) {
      localStorage.setItem('ref', String(route.queryParams.ref));
    } else {
        let ref: string; 
        const url = document.location.href;

        if (url.includes('?') && url.includes('?ref=')) {
          ref = url.split('?ref=')[1];
        }
        
        if (ref) {
          localStorage.setItem('ref', ref);
        }
    }
  }

  private setStorageProductPartnerLink(route: ActivatedRouteSnapshot): void {
    if(Object.keys(route.queryParams).length !== 0 && 
        route.queryParams.productIdentifier && 
        route.queryParams.marketingCode && 
        route.queryParams.applicationType) {
      localStorage.setItem('productIdentifier', String(route.queryParams.productIdentifier));
      localStorage.setItem('marketingCode', String(route.queryParams.marketingCode));
      localStorage.setItem('applicationType', String(route.queryParams.applicationType));
    }
  }

  private setStorageEmailNotifyParams(route: ActivatedRouteSnapshot): void {
    if(Object.keys(route.queryParams).length !== 0 && 
      route.queryParams.id && 
      route.queryParams.type && 
      route.queryParams.clientType) {
        localStorage.setItem('email_notify_param_id', String(route.queryParams.id));
        localStorage.setItem('email_notify_param_type', String(route.queryParams.type));
        localStorage.setItem('email_notify_param_clientType', String(route.queryParams.clientType));
    }
  }

  private setStorageMarketingPartnerLink(route: ActivatedRouteSnapshot): void {
    if(Object.keys(route.queryParams).length !== 0 && 
      route.queryParams.partnerId) {
        localStorage.setItem('partner_id', String(route.queryParams.partnerId));
    }
  }

  private setStorageEmailNotifyUnsubscribe(route: ActivatedRouteSnapshot): void {
    if(Object.keys(route.queryParams).length !== 0 && 
      route.queryParams.unsubscribe) {
        localStorage.setItem('unsubscribe', 'true');
    }
  }

  private storeEligibilityCheckerQueryParams(route: ActivatedRouteSnapshot): void {
    if(Object.keys(route.queryParams).length !== 0 && 
      route.queryParams.nationality && 
      route.queryParams.currentLocation && 
      route.queryParams.travelingTo && 
      route.queryParams.purpose && 
      route.queryParams.visaType) {
        let customQueryParams = new EligibilityChecker()
        customQueryParams.nationality = route.queryParams.nationality ? route.queryParams.nationality : null;
        customQueryParams.currentLocation = route.queryParams.currentLocation ? route.queryParams.currentLocation : null;
        customQueryParams.travelingTo = route.queryParams.travelingTo ? route.queryParams.travelingTo : null;
        customQueryParams.purpose = route.queryParams.purpose ? route.queryParams.purpose : null;
    
        if(route.queryParams.dateOfBirth) {
          customQueryParams.dateOfBirth = route.queryParams.dateOfBirth;
        }
    
        if(route.queryParams.hasJobOffer) {
          customQueryParams.hasJobOffer = Boolean(route.queryParams.hasJobOffer);
        }
        
        localStorage.setItem('eligibilityChecker', JSON.stringify(customQueryParams));
        localStorage.setItem('applicationType', route.queryParams.visaType);
    }
  }

  private getHostTheme(routeDetails: SubdomainURLModel): void {
    const hostName = window.location.hostname;
    const subDomainName = hostName.split('.')[0];
    const isExpectedDomain = subDomainName && this.enumHelperService.getNames(this.subdomainTypes).includes(subDomainName);

    const hostDetails: SubdomainURLModel = {
      host: subDomainName,
      applicationType: (isExpectedDomain && routeDetails.applicationType) && String(routeDetails.applicationType),
      productIdentifier: (isExpectedDomain && routeDetails.productIdentifier) && String(routeDetails.productIdentifier),
      marketingCode: (isExpectedDomain && routeDetails.marketingCode) && String(routeDetails.marketingCode)
    };
            
    this.themeHandlerService.setTheme(hostDetails);
  }

  private setReferralDetails(ref: string): void {
    const hostName = window.location.hostname;
    const isExpectedDomain = hostName === environment.refEnv;

    if (isExpectedDomain && ref) {
      sessionStorage.setItem('ref', ref);
    }
  } 
}
