import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService} from 'angular-oauth2-oidc';

import { authCodeFlowConfig } from 'src/app/identity-server.config';

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  constructor(
    private router: Router,
    private oauthService: OAuthService) {}

  ngOnInit(): void { 
    this.oauthService.configure(authCodeFlowConfig);

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
      this.router.navigate(['user']);
    });
  }

}
