import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FetchDescriptorTypes } from 'src/app/utilities/enums/fetch-descriptor-types';

@Injectable()
export class NumenclaturesCacheInterceptor implements HttpInterceptor {
  fetchDescriptorTypes: any;

  private cache: Map<string, HttpResponse<any>> = new Map();

  constructor() {
    this.fetchDescriptorTypes = FetchDescriptorTypes;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check is FetchDesc request stored in the cache
    const cachedResponse = this.cache.get(request.url);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    // If it is not in the cache - go to the next interceptor or make a new http request
    return next.handle(request).pipe(
      tap(event => {
        // Cache the request url
        if (event instanceof HttpResponse) {
          const urlParts = request.url.split('/');
          const fetchDescId = urlParts[urlParts.length - 1];
          if(request.url.includes(this.fetchDescriptorTypes[fetchDescId])) {
            this.cache.set(request.url, event);
          }
        }
      })
    );
  }
}
