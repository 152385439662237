import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

import { authCodeFlowConfig } from 'src/app/identity-server.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  constructor( 
    private oauthService: OAuthService) {
    this.oauthService.configure(authCodeFlowConfig);
  }
  
  public isAuthenticated(): boolean {
    if (
      this.oauthService.hasValidAccessToken &&
      this.oauthService.hasValidIdToken()
    ) {
      return true;
    } else {
      return false;
    }
  }

  public login() {
    this.oauthService.loadDiscoveryDocumentAndLogin();
    /**
     * add а custom query param that not exist in AuthConfig model
     */
    this.oauthService.customQueryParams = {
      'response_mode': 'query',
    };
  }

  public logout() {
    this.oauthService.logOut();
  }

  public getFullname(): string {
    let claims: any = this.oauthService.getIdentityClaims();
    if (claims && claims.name) {
      return claims.name;
    }
    return '';
  }
}
