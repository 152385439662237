import { PurposeType } from 'src/app/utilities/enums/purpose-type.enum';

export class EligibilityChecker {
    nationality: string;
    currentLocation: string;
    travelingTo: string;
    purpose: PurposeType;
    dateOfBirth?: Date;
    hasJobOffer?: boolean;
}
